import React from "react";
import Client1 from "../../assets/client1.png";
import Client4 from "../../assets/client4.png";
import Client5 from "../../assets/client5.png";

const OurClients = () => {
  return (
    <div id="client" className="w-full min-h-screen p-2 flex items-center bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Clients</h2>
          <p className="text-lg text-gray-700 mb-8">
            See what our clients have to say about us.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
         
        <div className="justify-center">
        <img
          className="w-24 h-24 object-cover rounded-full"
          src={Client1}
          alt="Client 1"
        />
        <div className="z-10 bg-white p-8 rounded-lg shadow-md">
          <p className="text-lg text-gray-700 mb-4">
            "Lorem Ipsum. Rerum qui libero. Architecto eum sit. Non ex quidem fuga. Eveniet vel reiciendis cum delectus molestiae. Maiores laborum veritatis corrupti. Sunt ducimus iste odit."
          </p>
          <p className="text-gray-500">Name, Job</p>
        </div>
      </div>
      <div className="justify-center">
      <img
        className="w-24 h-24 object-cover rounded-full"
        src={Client4}
        alt="Client 1"
      />
      <div className="z-10 bg-white p-8 rounded-lg shadow-md">
        <p className="text-lg text-gray-700 mb-4">
          "Lorem Ipsum. Laboriosam sint dolorem iste. Explicabo vero quidem. Eaque ut voluptatem ea omnis. Voluptatibus ad tenetur voluptas sequi velit. Consequatur sunt voluptatibus dignissimos est illum saepe esse vel. Non aut quaerat quidem quidem dolor et numquam."
        </p>
        <p className="text-gray-500">Name, Job</p>
      </div>
    </div>
    <div className="justify-center">
    <img
      className="w-24 h-24 object-cover rounded-full"
      src={Client5}
      alt="Client 1"
    />
    <div className="z-10 bg-white p-8 rounded-lg shadow-md">
      <p className="text-lg text-gray-700 mb-4">
        "Lorem Ipsum. Magni corrupti nam voluptates provident molestiae enim a voluptatem. Qui facilis explicabo tenetur sit voluptatum ut reiciendis vero sed. Quasi rerum nesciunt dolorem minima omnis rerum. Ducimus nemo et. Dignissimos rerum ab necessitatibus perspiciatis occaecati commodi quisquam voluptas. Enim non voluptatem error laboriosam placeat fugiat atque."
      </p>
      <p className="text-gray-500">Name, Job</p>
    </div>
  </div>
  </div>
  </div>
</div>
  );
};

export default OurClients;

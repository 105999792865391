import React from "react";
import Image from "../../assets/Yalero 1.jpg";

const AboutUs = () => {
  return (
    <div id="about" className="flex flex-col md:flex-row w-full h-screen bg-amber-800">

      <div className="w-full md:w-1/2 p-0 md:p-8 flex items-center justify-center ">
        
          <img
            src={Image}
            alt="About Us"
            className="w-full h-auto object-cover rounded-tr-full rounded-bl-ful"
          />
        </div>
  


      <div className="w-full md:w-1/2 p-4 md:p-8 bg-gray-100 flex items-center justify-center">
        <div className="text-center md:text-left">
          <h2 className="text-3xl md:text-5xl font-bold text-amber-800 mb-4">
            About Us
          </h2>
          <p className="text-lg md:text-xl text-gray-700 mb-8">
          Lorem Ipsum. Sed consectetur doloribus autem iste aut incidunt. Autem iusto saepe rerum reiciendis rerum a possimus. Fugit quo qui facere eos error. Ratione sint quos quod.
            <br/>
            <br/>
          Suscipit sint in autem quo voluptates magnam iure in architecto. Qui autem quo aperiam quis. Aspernatur impedit illo et repellendus natus. Magnam aut qui sit eum neque pariatur. Mollitia reprehenderit vitae dolor excepturi atque odio tenetur. Aut ad distinctio.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

import React from "react";
import ProductImage1 from "../../assets/Motor Oil 4.jpg";
import ProductImage2 from "../../assets/Cemento Stark 5800.jpg";
import ProductImage3 from "../../assets/Web Desarrollo.png";

const SaleProperties = () => {
  return (
    <div id="sale" className="w-full min-h-screen p-2 flex items-center bg-gray-100">
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
    <h2 className="text-4xl font-bold text-gray-800 mb-6">On Sale  <span className="text-amber-800">Products</span></h2>
    <p className="text-lg text-gray-700 mb-8">
        We offer a wide selection of on-sale products. Here are some of our featured products:
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        <div className="hover:shadow-md hover:shadow-[#040c16]">
          <div className="bg-white rounded-lg shadow-lg">
            <img src={ProductImage1} alt="Product" className="w-full max-h-64 object-cover rounded-t-lg h-1/3 md:h-64" />
            <div className="py-6 px-4">
              <h3 className="text-lg font-medium text-gray-900">15W-40</h3>
              <p className="text-sm text-gray-500">Tonel | 55 Gallons</p>
              <p class="text-lg font-bold text-gray-700 mt-4"><del>Q10,000</del> On Sale Now: Q8,000</p>
              <button className="mt-6 px-4 py-2 bg-amber-800 text-white rounded-md hover:bg-amber-700">
                View Details
              </button>
            </div>
          </div>
        </div>
        <div className="hover:shadow-md hover:shadow-[#040c16]">
          <div className="bg-white rounded-lg shadow-lg">
            <img src={ProductImage2} alt="Product" className="w-full max-h-64 object-cover rounded-t-lg h-1/3 md:h-64" />
            <div className="py-6 px-4">
              <h3 className="text-lg font-medium text-gray-900">STARK 5800</h3>
              <p className="text-sm text-gray-500">CD VILLA NUEVA</p>
              <p class="text-lg font-bold text-gray-700 mt-4"><del>Q100</del> On Sale Now: Q80</p>
              <button className="mt-6 px-4 py-2 bg-amber-800 text-white rounded-md hover:bg-amber-700">
                View Details
              </button>
            </div>
          </div>
        </div>
        <div className="hover:shadow-md hover:shadow-[#040c16]">
          <div className="bg-white rounded-lg shadow-lg">
            <img src={ProductImage3} alt="Product" className="w-full max-h-64 object-cover rounded-t-lg h-1/3 md:h-64" />
            <div className="py-6 px-4">
              <h3 className="text-lg font-medium text-gray-900">Landing Web Page</h3>
              <p className="text-sm text-gray-500">E-mails included</p>
              <p class="text-lg font-bold text-gray-700 mt-4"><del>Q7,000</del> On Sale Now: Q6,000</p>
              <button className="mt-6 px-4 py-2 bg-amber-800 text-white rounded-md hover:bg-amber-700">
                View Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default SaleProperties;
